import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Deadlifts 4×6\\@80%1RM`}</p>
    <p>{`then,`}</p>
    <p>{`50 Calorie Assault Bike`}</p>
    <p>{`40 Deadlifts (225/155)`}</p>
    <p>{`30 HSPU’s`}</p>
    <p>{`20 Box Jumps (30/24″)`}</p>
    <p>{`10 Fifty Foot Shuttle Runs`}</p>
    <p>{`for time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Thursday is the 4th of July.  We will have our FREE class at
10:00am.  This will be our only class on the 4th, all other classes and
open gym will be cancelled so come out and join in the fun!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      